import { Controller } from '@suttyweb/stimulus'

export default class extends Controller {
  static targets = [ 'item', 'extra', 'toggler' ]

  connect () {
    window.addEventListener('scroll:section', event => this.update(event.detail))
  }

  get items () {
    if (!this._items) {
      this._items = {}

      for (const item of this.itemTargets) {
        this._items[item.href.split('#')[1]] = item
      }
    }

    return this._items
  }

  toggle (event = undefined) {
    if (!this.hasTogglerTarget) return

    this.togglerTarget.checked = !this.togglerTarget.checked
  }

  update (detail) {
    const id = detail.id

    for (const item of Object.values(this.items)) {
      item.classList.remove('active')
    }

    if (this.items[id]) this.items[id].classList.add('active')

    if (!detail.extra) return

    for (const extra of this.extraTargets) {
      if (this.previousExtra) extra.classList.remove([extra.dataset.prefix, this.previousExtra].join("-"))
      extra.classList.add([extra.dataset.prefix, detail.extra].join("-"))
    }

    this.previousExtra = detail.extra
  }
}
