import { Controller } from '@suttyweb/stimulus'

export default class extends Controller {
  static values = {
    filters: Array,
    noMatchClass: String
  }

  static targets = [ 'item' ]

  connect () {
    this.filtering = false
  }

  get filters () {
    if (!this._filters) this._filters = {}

    return this._filters
  }

  /*
   * @param [String]
   * @param [Array]
   */
  toggleFilters (term, values, toggle = true) {
    if (!this.filters[term]) this.filters[term] = []

    if (toggle) {
      this.filters[term] = [...new Set(this.filters[term].concat(values))]
    } else {
      this.filters[term] = this.filters[term].filter(x => !values.includes(x))
    }
  }

  empty_categories () {
    delete this.filters.categories
  }

  apply_filters () {
    // No queremos filtrar recursivamente!
    if (this.filtering) return

    this.filtering = true

    // Por cada item buscar las coincidencias
    for (const item of this.itemTargets) {
      const filter_mode = item.dataset.filterMode || 'some'
      const matches = []

      // Aplicar filtros.  La entrevista matchea si entre sus términos
      // se encuentran todos los términos de búsqueda.
      for (const term in this.filters) {
        if (this.filters[term].length === 0) continue

        const term_haystack = JSON.parse(item.dataset[term] || "[]")

        matches.push(this.filters[term][filter_mode](x => term_haystack.includes(x)))
      }

      // Si el filtro está vacío, mostramos todos los items en lugar de
      // ninguno.
      if (this.is_empty() && 'showOnEmpty' in item.dataset && filter_mode === 'some') matches.push(true)

      const needle = matches.length > 0 && matches[filter_mode](x => x)

      item.classList[needle ? 'remove' : 'add'](item.dataset.noMatchClass)
      if ('check' in item.dataset) item.checked = needle
    }

    // Permitir volver a filtrar
    this.filtering = false
  }

  // Cuando necesitamos limpiar los filtros
  empty_filters () {
    this._filters = {}
  }

  save_filters (event) {
    this._saved_filters = this._filters
  }

  recover_filters (event) {
    this._filters = this._saved_filters
    this.apply_filters()
  }

  remove_filters (event) {
    const remove = event?.currentTarget?.dataset?.filter

    if (!remove) return

    delete this.filters[remove]

    this.apply_filters()
  }

  // Cuando al eliminar el filtro necesitamos volver a mostrar lo mismo
  // que estábamos viendo, pero sin cambiar el estado.
  show_self (event) {
    event.preventDefault()
    event.currentTarget.classList.remove(event.currentTarget.dataset.noMatchClass)
  }

  // Aplicar los filtros según el dataset del elemento
  filter (event) {
    event.preventDefault()

    // El elemento que tiene los filtros
    const filter = event.currentTarget

    // No hacer nada sin elemento
    if (!filter) return

    // Siempre filtramos, pero a veces queremos filtrar solo cuando el
    // filtro es true
    if (!filter.checked && 'filterOnChecked' in filter.dataset) return

    // Filtrar por los tags
    for (const term of this.filtersValue) {
      if (filter.dataset[term]) this.toggleFilters(term, JSON.parse(filter.dataset[term]), filter.checked)
    }

    this.apply_filters()
  }

  // Detecta si los filtros están vacíos
  is_empty () {
    return (Object.keys(this.filters).length === 0 || Object.values(this.filters).map(x => x.length === 0).every(x => x))
  }
}
