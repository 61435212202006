import { Controller } from '@suttyweb/stimulus'

/*
 * Al navegar por el sitio y llegar a ciertas secciones, se van
 * activando ítems del menú.
 *
 * Para eso configuramos un IntersectionObserver en todo el documento y
 * a medida que van apareciendo secciones actualizamos el menú.
 */
export default class extends Controller {
  static targets = [ 'section' ]

  connect () {
    this.options = { threshold: 0.10, rootMargin: '0px' }

    for (const section of this.sectionTargets) {
      this.observer.observe(section)
    }
  }

  /*
   * Solo nos interesa la primera
   */
  get observer () {
    if (!this._observer) this._observer = new IntersectionObserver((entries, observer) => this.update(entries), this.options)

    return this._observer
  }

  update (entries) {
    const section = entries.find(x => x.intersectionRatio >= 0.10)

    if (!section) return

    window.dispatchEvent(new CustomEvent('scroll:section', { detail: { id: section.target.id, extra: section.target.dataset.extra }}))
  }
}
