import { Controller } from '@suttyweb/stimulus'
import anime from 'animejs/lib/anime.es.js'

// Basado en
// https://jsfiddle.net/4879trjv/1/
export default class extends Controller {
  static targets = [ 'space', 'smiley' ]

  // Cada smiley tiene su propia timeline, de forma que se puedan mover
  // independientemente.
  connect () {
    for (const smiley of this.smileyTargets) {
      this.startAnimation(smiley)
    }
  }

  // Al tocar una smiley queremos pausarla, al tocarla de vuelta
  // queremos que retome el movimiento.
  //
  // El resto de las smileys recuperan su movimiento también.
  toggle (event = undefined) {
    const target = event?.currentTarget
    let cancel_event = target?.dataset?.universeCancelEventParam
    if (cancel_event === undefined) cancel_event = true

    if (cancel_event === true) {
      event?.preventDefault()
      event?.stopPropagation()
    }

    for (const smiley of this.smileyTargets) {
      if (smiley.dataset.uuid === target?.id) {
        target?.checked ? this.timelines(smiley).pause() : this.timelines(smiley).play()
      } else {
        this.timelines(smiley).play()
      }
    }

  }

  // Reproducir audio
  play (event) {
    event.preventDefault()
    event.stopPropagation()

    const audio = this.element.querySelector(event?.currentTarget?.dataset?.audio)

    audio?.paused ? audio?.play() : audio?.pause()
  }

  startAnimation (smiley) {
    // Cada ciclo tiene su propia duración
    const duration = anime.random(10000, 20000)
    // Todas las smileys se mueven al mismo tiempo
    const offset = 0

    this.timelines(smiley).add({
      targets: smiley,
      easing: 'linear',
      duration: duration,
      translateX: [
        { value: this.randomXMovement(smiley) },
        { value: this.randomXMovement(smiley) },
        { value: this.randomXMovement(smiley) },
      ],
      translateY: [
        { value: this.randomYMovement(smiley) },
        { value: this.randomYMovement(smiley) },
        { value: this.randomYMovement(smiley) },
      ]
    }, offset)
  }

  // Configura una timeline infinita para cada smiley
  timelines (smiley) {
    if (!this._timelines) this._timelines = {}

    const uuid = smiley.dataset.uuid

    if (!uuid) {
      console.error('La smiley no tiene un UUID asociado')
      return
    }

    if (!this._timelines[uuid]) {
      this._timelines[uuid] = anime.timeline({
        loop: false,
        complete: function () {
          this._timelines[uuid] = null
          this.startAnimation(smiley)
        }.bind(this)
      })
    }

    return this._timelines[uuid]
  }

  get spaceWidth () {
    return this.spaceTarget.clientWidth
  }

  get spaceHeight () {
    return this.spaceTarget.clientHeight
  }

  randomXMovement (smiley) {
    return `${anime.random(0, this.spaceWidth - smiley.clientWidth)}px`
  }

  // El 0 no es el 0 del espacio sino del contenedor del smiley, que no
  // es el espacio.  Para poder calcularlo tenemos que restarle a 0 la
  // posición del contenedor del smiley con respecto al espacio.
  //
  // Podríamos usar position-absolute top-0 right-0 pero nos afecta el
  // comportamiento de los popups, que necesitan posicionarse también
  // con respecto al espacio y no al contenedor de la smiley.
  randomYMovement (smiley) {
    // XXX: Esto implica la estructura space > parent > smiley
    const offset = 0 - smiley.parentElement.offsetTop

    return `${anime.random(offset, this.spaceHeight + offset - smiley.clientHeight)}px`
  }
}
