import BotDetector from "device-detector-js/dist/parsers/bot";
import { Notifier } from "@airbrake/browser";

window.botDetector = new BotDetector();
window.bot = window.botDetector.parse(navigator.userAgent);

if (!window.bot && window.env.JEKYLL_ENV === "production") {
  try {
    window.airbrake = new Notifier({
      projectId: window.env.AIRBRAKE_PROJECT_ID,
      projectKey: window.env.AIRBRAKE_PROJECT_KEY,
      host: "https://panel.sutty.nl",
    });

    console.originalError = console.error;
    console.error = (...e) => {
      window.airbrake.notify(e.join(" "));
      return console.originalError(...e);
    };
  } catch(e) {
    console.error(e);
  }
}

import "regenerator-runtime/runtime";

// Turbo acelera la navegación al no tener que recargar todo el JS y CSS
// de la página, con lo que se siente más rápida y "nativa".
//
// Cambiamos de turbolinks a turbo porque turbo soporta la función
// fetch(), que luego es interceptada por el SW para obtener las
// direcciones localmente.
import * as Turbo from "@hotwired/turbo"
Turbo.start()

import { Application } from '@suttyweb/stimulus'
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Acciones al terminar de cargar la página e iniciar Turbo.
document.addEventListener('turbo:load', event => {
  // Los links externos se abren por fuera de la app
  document.querySelectorAll("a[href^='http://'],a[href^='https://'],a[href^='//']").forEach(a => {
    if (a.href.startsWith(window.location.origin)) return

    a.rel = "noopener noreferrer"
    a.target = "_blank"
  })
})
