import { Controller } from "@suttyweb/stimulus";

export default class extends Controller {
  static values = {
    title: String,
    text: String,
    url: String,
    clipboard: String,
  }

  static targets = [ "fallback" ];

  async share (event = undefined) {
    event?.preventDefault();
    event?.stopPropagation();

    if (await this.navigatorShare()) return;
    if (await this.navigatorClipboard() || await this.documentExecCommand()) this.copied();
  }

  async navigatorShare() {
    const title = this.titleValue;
    const text = this.textValue;
    const url = this.urlValue;
    const data = { title, text, url };

    if (!("share" in navigator)) return false;

    try {
      if ("canShare" in navigator) {
        if (navigator.canShare(data)) {
          await navigator.share(data);
        } else {
          console.error("No se puede compartir", data);
        }
      } else {
        await navigator.share(data);
      }
    } catch(e) {
      switch (e.name) {
        case "AbortError": return true;
        case "TypeError":
          console.error(e);
        default:
          return false;
      }
    }

    return true;
  }

  async navigatorClipboard() {
    if (!('clipboard' in navigator)) return false;
    if (!this.hasFallbackTarget) return false;

    try {
      await navigator.clipboard.writeText(this.fallbackTarget.value);
    } catch(e) {
      return false;
    }

    return true;
  }

  async documentExecCommand() {
    if (!this.hasFallbackTarget) return false;

    this.fallbackTarget.select();

    return document.execCommand("copy");
  }

  copied () {
    const content = this.clipboardValue.trim();

    if (content.length === 0) return;

    window.dispatchEvent(new CustomEvent("toast", { detail: { content }}));
  }
}
