import { Controller } from '@suttyweb/stimulus'

/*
 * Sólo permite enviar el formulario de contacto después de unos
 * segundos, para evitar el spam.
 */
export default class extends Controller {
  static targets = [ 'submit' ]

  connect () {
    if (!this.hasSubmitTarget) return

    this.submitTarget.disabled = true

    this._value = this.submitTarget.value

    // Esperar un minuto desde que se carga la página hasta que se
    // permite enviar.
    this._interval = setInterval(() => {
      const delay = this.delay

      if (this.delay == 0) {
        clearInterval(this._interval)
        this.submitTarget.disabled = false
        this.submitTarget.value = this._value
      } else {
        this.delay = delay - 1
      }
    }, 1000)
  }

  get delay () {
    const delay = parseInt(this.element.dataset.delay)

    return isNaN(delay) ? 0 : delay
  }

  set delay (value) {
    this.element.dataset.delay = value
    this.submitTarget.value = `${this._value} (${value})`
  }
}
