import { Controller } from '@suttyweb/stimulus'

/*
 * Genera un acordeón haciendo que una tira de checkboxes se comporte
 * como un radio.  Usamos checkboxes porque los radio no facilitan la
 * navegación con teclado (especial para lectores de pantalla) cuando
 * tienen contenido y no se pueden destildar una vez seleccionados.
 */
export default class extends Controller {
  static targets = [ 'input' ]

  check (event) {
    if (!event?.currentTarget?.checked) return

    for (const other of this.inputTargets) {
      if (event?.currentTarget !== other) other.checked = false
    }
  }
}
